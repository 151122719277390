body {
  margin: 0;
  padding: 0;
}

* {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button,
a {
  cursor: pointer;
}

button {
  border: none;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
}

/* 글꼴 */
.ql-font-pretendard {
  font-family: "Pretendard", sans-serif;
}

.ql-font .ql-picker-label {
  display: flex !important;
  align-items: center !important;
}

.ql-font .ql-picker-label::before {
  content: "" !important;
  font-family: "Pretendard", sans-serif !important;
  padding-top: 5px !important;
}

.ql-font .ql-picker-item[data-value="pretendard"]::before {
  content: "프리텐다드" !important;
  font-family: "Pretendard", sans-serif !important;
  font-weight: normal !important;
}

.ql-font-nanum-gothic {
  font-family: "Nanum Gothic", sans-serif;
}

.ql-font .ql-picker-item[data-value="nanum-gothic"]::before {
  content: "나눔고딕" !important;
  font-family: "Nanum Gothic", sans-serif !important;
  font-weight: normal !important;
}

.ql-font-nanum-square {
  font-family: "NanumSquare", sans-serif;
}

.ql-font .ql-picker-item[data-value="nanum-square"]::before {
  content: "나눔스퀘어" !important;
  font-family: "NanumSquare", sans-serif !important;
  font-weight: normal !important;
}

.read-editor .ql-container {
  border: none !important;
  word-break: keep-all !important;
}

.read-editor .ql-container .ql-editor {
  line-height: 30px !important;
}

.ql-editing {
  left: -10px !important;
  top: 30px !important;
}
