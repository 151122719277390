/* 토스트팝업 설정 */
.toast {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32.5px;
  text-align: center;
  letter-spacing: -0.054px;
  color: #ffffff;
}

.Toastify__toast-container {
  padding: 0px !important;
  width: unset !important;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
  width: 1920px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Toastify__toast-theme--light {
  background: rgba(0, 0, 17, 0.5) !important;
  border-radius: 999px !important;
  font-family: Pretendard !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21.5px;
  letter-spacing: -0.054px;
  text-align: center;
  color: #ffffff !important;
}

.Toastify__toast-body {
  justify-content: center;
  gap: 5px;
  padding: 6px 30px !important;
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: none !important;
}

.Toastify__toast {
  min-width: 236px;
  width: auto;
  padding: 0 !important;
  min-height: 45px !important;
  height: auto;
  /* margin-bottom: 0px !important; */
}

.Toastify__close-button {
  display: none;
}

.Toastify__toast-icon {
  margin-inline-end: 0 !important;
  width: 6px !important;
  height: 6px;
}

/* 인풋 커스텀 */
input[type="date"] {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  color: transparent;
  cursor: pointer;
}

input[type="date"]::before {
  content: attr(data-placeholder);
  width: 100%;
}

input[type="date"]:valid {
  color: #262c31;
}

input[type="date"]:valid::before {
  display: none;
}

input::-webkit-inner-spin-button {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

/* 테이블 스타일 */
tbody > tr {
  border-bottom: 0.5px solid #dfe1e450;
}

/* react-quill */
.ql-container.ql-snow {
  border: 1px solid #ccc;
  min-height: 120px;
}

/* 스피너 */
@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000050;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
}
.loading-spinner img {
  animation: loading-spinner 1s linear infinite;
  top: 50px;
  left: 50px;
}
.loading-spinner-wrapper {
  width: 120px;
  height: 120px;
  display: inline-block;
  overflow: hidden;
  background: rgba(NaN, NaN, NaN, 0);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}
.loading-spinner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.loading-spinner div {
  box-sizing: content-box;
}

.quill-detail img {
  max-width: 740px;
}

.ql-editor {
  min-height: 120px !important;
}
